/* eslint-disable no-undef */

import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  generat_Otp,
  getUserData,
  validateUser,
  verifyOTPFunc,
} from "../../reducers/Auth";
import {
  Form,
  Button,
  Spinner,
  InputGroup,
  FormControl,
} from "react-bootstrap";
import { FaMobileAlt } from "react-icons/fa";
import { MdOutlineMail } from "react-icons/md";
import OtpInput from "react-otp-input";
import { Toaster } from "react-hot-toast";
import logo from "../../assets/images/logo-light.svg";
import "./Auth.css";

const Auth = ({
  generat_Otp,
  error,
  isPending,
  generateOTP,
  VerifidOTPState,
  verifyOTPFunc,
  getUserData,
}) => {
  const [checkBoxValue, setCheckBoxValue] = useState(true);
  const [current, setCurrent] = useState(0);
  const [mobileNumer, setMobileNumber] = useState(null);
  const [emailAddress, setEmailAddress] = useState(null);
  const [generateOTPResponse, setGenerateOTPResponse] = useState(null);
  const [isLoadingGenerate, setIsLoadingGen] = useState();
  const [isLoadingVerify, setIsLoadingVerify] = useState();
  const [userID, setUserID] = useState("");
  const [otp, setOtp] = useState("");
  const [mobileError, setMobileError] = useState("");
  const [emailError, setEmailError] = useState("");

  const onChange = () => {
    setCheckBoxValue(!checkBoxValue);
  };
  const validateMobileNumber = (number) => {
    if (mobileNumer) {
      const regex = /^9715\d{8}$/; // Regex to match 9715 followed by 8 digits
      return regex.test(number);
    }
    if (emailAddress) {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(emailAddress);
    }
  };
  const validateEmail = (email) => {
    if (emailAddress) {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(email);
    }
  };

  const onFinish = async () => {
    setMobileError("");
    setEmailError("");
    if (checkBoxValue) {
      // if (!validateMobileNumber(mobileNumer)) {
      //   setMobileError(
      //     "Please enter a valid UAE mobile number (e.g., 9715xxxxxxxx)."
      //   );
      //   return; // Stop form submission if validation fails
      // }
    } else {
      if (!validateEmail(emailAddress)) {
        setEmailError("Please enter a valid email address.");
        return; // Stop form submission if validation fails
      }
    }

    setIsLoadingGen(true);
    await generat_Otp(mobileNumer, emailAddress);
    setIsLoadingGen(false);
    setCurrent(1);
  };

  useEffect(() => {
    if (isLoadingGenerate === false) {
      setGenerateOTPResponse({
        access_token: generateOTP.access_token,
        timestamp: generateOTP.timestamp,
        mobile_Number: checkBoxValue ? mobileNumer : undefined,
        email: !checkBoxValue ? emailAddress : undefined,
      });
    }
  }, [isLoadingGenerate]);

  const onVerifyOTPFinish = async () => {
    setIsLoadingVerify(true);
    await verifyOTPFunc(
      otp,
      generateOTPResponse?.timestamp,
      generateOTPResponse?.access_token,
      generateOTPResponse?.mobile_Number,
      generateOTPResponse?.email,
    );
    setIsLoadingVerify(false);
  };

  useEffect(() => {
    if (isLoadingVerify === false) {
      if (VerifidOTPState?.access_token && VerifidOTPState?.object_id) {
        const ver = async () => {
          localStorage.setItem("access_token", VerifidOTPState.access_token);
          localStorage.setItem("userObjectId", VerifidOTPState.object_id);
          setUserID(VerifidOTPState.object_id);
          await getUserData(VerifidOTPState.object_id);
        };
        ver();
      }
    }
  }, [isLoadingVerify]);

  return (
    <>
      <section className={`login`}>
        <div className={"signInFormContainer"}>
          <img src={logo} alt="logo" className="logo" width={120} />
          <div className={"content"}>
            <Spinner animation="border" role="status" hidden={true}>
              <span className="visually-hidden">Loading...</span>
            </Spinner>
            <p className={"title"}>Sign In by</p>
            <div className="switcher">
              <p>Mobile number</p>
              {/* Custom Switch (not available in React Bootstrap v1.6.7) */}

              <label className="switch">
                <input
                  type="checkbox"
                  // checked={checkBoxValue}
                  onChange={onChange}
                ></input>
                <span className="slider"></span>
              </label>

              <p>Email address</p>
            </div>
            {/* Custom Steps (not available in React Bootstrap v1.6.7) */}

            {current === 0 && (
              <Form
                name="generate otp"
                className={"signInForm"}
                initialValues={{}}
                onSubmit={onFinish}
                style={{
                  marginTop: "30px",
                }}
              >
                <Form.Group
                  controlId={checkBoxValue ? "mobile_number" : "email_address"}
                >
                  <Form.Label>
                    {checkBoxValue ? "Mobile Number" : "Email Address"}
                  </Form.Label>

                  {/* email */}
                  <InputGroup>
                    {/* <InputGroup.Prepend>
                      <InputGroup.Text className="inputGroupPrepend">
                        {checkBoxValue ? <FaMobileAlt /> : <MdOutlineMail />}
                      </InputGroup.Text>
                    </InputGroup.Prepend> */}
                    {checkBoxValue ? (
                      <FormControl
                        key="1"
                        placeholder="Enter the mobile number"
                        style={{
                          padding: "15px",
                          borderRadius: "8px",
                          fontSize: "17px",
                          border: "1px solid #ced4da",
                          height: "42px",
                        }}
                        onChange={(e) => {
                          setMobileNumber(e.target.value);
                        }}
                        required
                      />
                    ) : (
                      <FormControl
                        placeholder="Enter the Email address"
                        key="2"
                        style={{
                          padding: "15px",
                          borderRadius: "8px",
                          fontSize: "17px",
                          border: "1px solid #ced4da",
                          height: "42px",
                        }}
                        onChange={(e) => {
                          setEmailAddress(e.target.value);
                        }}
                        required
                      />
                    )}
                  </InputGroup>
                  <Form.Control.Feedback
                    type="invalid"
                    className="custom-error"
                  >
                    {checkBoxValue && mobileError && mobileError}
                    {!checkBoxValue && emailError && emailError}
                  </Form.Control.Feedback>
                </Form.Group>
                <Button
                  variant="primary"
                  type="submit"
                  size="lg"
                  className={"signInButton"}
                  disabled={isLoadingGenerate}
                >
                  {isLoadingGenerate ? "Sending OTP..." : "Send OTP"}
                </Button>
              </Form>
            )}
            {current === 1 && (
              <Form
                name="verify otp"
                className={"signInForm"}
                initialValues={{}}
                style={{
                  marginTop: "30px",
                }}
              >
                <Form.Group controlId="otp">
                  <Form.Label>OTP</Form.Label>
                  <OtpInput
                    value={otp}
                    onChange={setOtp}
                    numInputs={4}
                    className="otp-input"
                    renderInput={(props) => (
                      <input
                        {...props}
                        style={{
                          width: "77px",
                          height: "46px",
                          marginRight: "18px",
                          padding: "11px 4px",
                          borderRadius: "8px",
                          border: "2px solid #eee",
                          textAlign: "center",
                          justifyContent: "space-between",
                        }}
                      />
                    )}
                  />
                </Form.Group>
                <Button
                  variant="primary"
                  type="submit"
                  size="lg"
                  className={"signInButton"}
                  disabled={isLoadingVerify}
                  onClick={onVerifyOTPFinish}
                >
                  {isLoadingVerify ? "Validating OTP..." : "Validate OTP"}
                </Button>
              </Form>
            )}
          </div>
        </div>
      </section>
      <Toaster />
    </>
  );
};

const mapStateToProps = (state) => ({
  isPending: state.Auth.isPending,
  error: state.Auth.error,
  user: state.Auth.user,
  isLogged: state.Auth.isLoggedIn,
  generateOTP: state.Auth.generateOTP,
  VerifidOTPState: state.Auth.verify_OTp,
  userDataState: state.Auth.user_Data,
  UserVerify: state.Auth.user,
});

const actions = (dispatch) => ({
  generat_Otp: (mobileNumer, email) =>
    dispatch(generat_Otp(mobileNumer, email)),
  verifyOTPFunc: (otp, timestamp, access_token, mobileNumber, email) =>
    dispatch(verifyOTPFunc(otp, timestamp, access_token, mobileNumber, email)),
  getUserData: (userId) => dispatch(getUserData(userId)),
  validateUser: (auth, objectID) => dispatch(validateUser(auth, objectID)),
});

export default connect(mapStateToProps, actions)(Auth);

Auth.propTypes = {
  generat_Otp: PropTypes.func.isRequired,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  isPending: PropTypes.bool.isRequired,
};
Auth.propTypes = {
  getUserData: PropTypes.func.isRequired,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  isPending: PropTypes.bool.isRequired,
};
Auth.propTypes = {
  validateUser: PropTypes.func.isRequired,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  isPending: PropTypes.bool.isRequired,
};

Auth.propTypes = {
  generateOTP: PropTypes.oneOfType([
    PropTypes.shape({
      access_token: PropTypes.string.isRequired,
      timestamp: PropTypes.number.isRequired,
    }),
    PropTypes.array,
  ]),
};

Auth.propTypes = {
  verifyOTPFunc: PropTypes.func.isRequired,
  verifyOTPFuncArgs: PropTypes.shape({
    otp: PropTypes.string.isRequired,
    timestamp: PropTypes.number.isRequired,
    access_token: PropTypes.string.isRequired,
    mobileNumber: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
  }),
};

Auth.propTypes = {
  VerifidOTPState: PropTypes.oneOfType([
    PropTypes.shape({
      access_token: PropTypes.string.isRequired,
      object_id: PropTypes.string.isRequired,
      new_user: PropTypes.bool.isRequired,
    }),
    PropTypes.array,
  ]),
};

Auth.propTypes = {
  userDataState: PropTypes.oneOfType([
    PropTypes.shape({
      email: PropTypes.string.isRequired,
      error: PropTypes.string,
      lastOrderDate: PropTypes.shape({
        __type: PropTypes.string.isRequired,
        iso: PropTypes.string.isRequired,
      }),
      lastOrderValue: PropTypes.number,
      ltv: PropTypes.number,
      mobile_number: PropTypes.string.isRequired,
      objectId: PropTypes.string.isRequired,
      ordersNum: PropTypes.number,
      peek_hour: PropTypes.number,
      push_enabled: PropTypes.bool,
      role: PropTypes.string.isRequired,
      smartlink_id: PropTypes.string,
      top_hour: PropTypes.number,
      updatedAt: PropTypes.string.isRequired,
      userId: PropTypes.string.isRequired,
      webpush_enabled: PropTypes.bool,
    }),
    PropTypes.array,
    PropTypes.object,
    PropTypes.string,
  ]),
};
