import React from "react";
import { connect } from "react-redux";
import { toggleMobileNavVisibility } from "../../reducers/Layout";
import {
  Navbar,
  Nav,
  NavItem,
  NavDropdown,
  MenuItem,
  FormGroup,
  FormControl,
} from "react-bootstrap";
import { Link, Redirect } from "react-router-dom";
import { Logout } from "../../reducers/Auth";

const Header = ({ showMobileMenu, toggleMobileNavVisibility, Logout }) => {
  const handleLogout = async () => {
    await Logout();
    sessionStorage.clear();
    localStorage.clear();
    window.location.reload(false);
  };
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "end",
        alignItems: "center",
        width: "100%",
        padding: " 20px",
      }}
    >
      <Link to="/" onClick={handleLogout}>
        Log out <i className="fa fa-sign-out logoutIcon"></i>
      </Link>
    </div>
  );
};

const mapStateToProp = (state) => ({
  Logout: state.Auth.logout,
});

const mapDispatchToProp = (dispatch) => ({
  toggleMobileNavVisibility: () => dispatch(toggleMobileNavVisibility()),
  Logout: () => dispatch(Logout()),
});

export default connect(mapStateToProp, mapDispatchToProp)(Header);
