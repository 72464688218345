import React, { useState } from "react";
import { Route, Router, Switch, withRouter } from "react-router-dom";
import { connect, useSelector } from "react-redux";
import cx from "classnames";

import { setMobileNavVisibility } from "../../reducers/Layout";

import Header from "./Header";
import Footer from "./Footer";
import SideBar from "../../components/SideBar";
import Auth from "../Auth";
/**
 * Pages
 */
import Dashboard from "../Dashboard";
import Components from "../Components";

import SelectedVideo from "../SelectedVideo";
import AddDemo from "../SelectedVideo/AddDemo";
import Testimonials from "../Testimonials";
import AddTestimonial from "../Testimonials/AddTestimonial";
import Gallery from "../Gallery";
import AddTeams from "../Gallery/AddGallery";
import Notification from "../Notification";
import AddNotification from "../Notification/AddNotification";
import { ToastContainer } from "react-toastify";

const Main = ({ mobileNavVisibility, hideMobileMenu, history, isLoggedIn }) => {
  // history.listen(() => {
  //   if (mobileNavVisibility === true) {
  //     hideMobileMenu();
  //   }
  // });
  const userlogin = sessionStorage.getItem("user");
  const isAlreadyLoggedIn = localStorage.getItem("userloggedin");

  return (
    <div
    // className={cx({
    //   "nav-open": mobileNavVisibility === true,
    // })}
    >
      {isAlreadyLoggedIn === "false" || isAlreadyLoggedIn === null ? (
        <Auth />
      ) : (
        <div className="wrapper">
          <div className="close-layer" onClick={hideMobileMenu}></div>
          <SideBar />

          <div className="main-panel">
            <Header />
            <Switch>
              <Route path="/selectedVideos" component={SelectedVideo} />
              <Route
                path={["/addvideo", "/editvideo/:id"]}
                component={AddDemo}
              />

              <Route path="/testimonials" component={Testimonials} />
              <Route
                path={["/addTestimonial", "/editTestimonial/:id"]}
                component={AddTestimonial}
              />
              <Route path="/gallery" component={Gallery} />
              <Route
                path={["/addGallery", "/editGallery/:id"]}
                component={AddTeams}
              />
              <Route path="/notification" component={Notification} />
              <Route
                path={["/addNotification", "/editNotification/:id"]}
                component={AddNotification}
              />

              <Route exact path="/" component={Dashboard} />
              <Route path="/components" component={Components} />
            </Switch>
            <Footer />
          </div>
        </div>
      )}

      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover={false}
        theme="light"
      />
    </div>
  );
};

const mapStateToProp = (state) => ({
  mobileNavVisibility: state.Layout.mobileNavVisibility,
  isLoggedIn: state.Auth.isLoggedIn,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  hideMobileMenu: () => dispatch(setMobileNavVisibility(false)),
});

export default withRouter(connect(mapStateToProp, mapDispatchToProps)(Main));
