import { appBackendAppgainUrl, getConfigHeaders } from "../api";

const url = "selectedVideos";

const defaultState = {
  demos: [],
  demo: {},
  error: null,
  isPending: null,
  loaded: false,
};

const GET_DEMOS = "GET_DEMOS";
const DELETE_DEMO = "DELETE_DEMO";
const DEMOS_PENDING = "DEMOS_PENDING";
const DEMO_PENDING = "DEMO_PENDING";
const GET_DEMO = "GET_DEMO";
const DEMO_ERROR = "DEMO_ERROR";
const DEMOS_ERROR = "DEMOS_ERROR";
const EDIT_DEMO = "EDIT_DEMO";
const CREATE_DEMO = "CREATE_DEMO";

// GET demos
export const getDemos = () => async (dispatch, getState) => {
  dispatch({
    type: DEMOS_PENDING,
    payload: true,
  });
  try {
    const data = await appBackendAppgainUrl(
      `${url}`,
      "get",
      getConfigHeaders(),
    );

    dispatch({
      type: GET_DEMOS,
      payload: data.results,
    });
  } catch (error) {
    dispatch({
      type: DEMOS_ERROR,
      payload: error,
    });
  }
};

// GET demo

export const getDemo = (personId) => async (dispatch, getState) => {
  dispatch({
    type: DEMO_PENDING,
    payload: true,
  });
  try {
    const data = await appBackendAppgainUrl(
      `${url}/${personId}`,
      "get",
      getConfigHeaders(),
    );
    dispatch({
      type: GET_DEMO,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: DEMO_ERROR,
      payload: error,
    });
  }
};

// Create demo
export const createDemo = (newDemoData) => async (dispatch, getState) => {
  dispatch({
    type: DEMOS_PENDING,
    payload: true,
  });
  try {
    const data = await appBackendAppgainUrl(
      `${url}`,
      "post",
      newDemoData,
      getConfigHeaders(),
    );

    dispatch({
      type: CREATE_DEMO,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: DEMOS_ERROR,
      payload: error,
    });
  }
};

export const editDemo = (id, newDemosData) => async (dispatch) => {
  dispatch({
    type: DEMO_PENDING,
    payload: true,
  });
  try {
    await appBackendAppgainUrl(
      `${url}/${id}`,
      "put",
      newDemosData,
      getConfigHeaders(),
    );
    dispatch({
      type: EDIT_DEMO,
      payload: { id, newDemosData },
    });
  } catch (error) {
    dispatch({
      type: DEMO_ERROR,
      payload: error,
    });
  }
};

// Delete demos Person
export const deleteDemo = (id) => async (dispatch, getState) => {
  dispatch({
    type: DEMOS_PENDING,
    payload: true,
  });
  try {
    await appBackendAppgainUrl(`${url}/${id}`, "delete", getConfigHeaders());

    dispatch({
      type: DELETE_DEMO,
      payload: id,
    });
  } catch (error) {
    dispatch({
      type: DEMOS_ERROR,
      payload: error,
    });
  }
};

export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case DEMO_PENDING:
    case DEMOS_PENDING:
      return { ...state, isPending: true };

    case GET_DEMOS:
      return {
        ...state,
        demos: action.payload,
        loaded: true,
        isPending: false,
      };

    case CREATE_DEMO:
      return {
        demos: [...state.demos, action.payload],
        isPending: false,
        loaded: true,
      };

    case EDIT_DEMO:
      return {
        ...state,
        demos: state.demos.map((memory) =>
          memory.objectId === action.payload.id
            ? action.payload.newDemosData
            : memory,
        ),
        isPending: false,
        loaded: true,
      };

    case GET_DEMO:
      return {
        ...state,
        demo: action.payload,
        loaded: true,
        isPending: false,
      };

    case DELETE_DEMO:
      return {
        ...state,
        demos: state.demos.filter(
          (person) => person.objectId !== action.payload,
        ),
        loaded: true,
        isPending: false,
      };
    default:
      return { ...state };
  }
}
